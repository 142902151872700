import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../../static/icons/iconmonstr-facebook-5.svg';
import ExternalLink from '../common/ExternalLink';
import {Container} from '../global';


const Footer = () => (
    <FooterWrapper>
        <StyledContainer>
            <Container>
                <p><small>3838 Martin Luther King Jr Pkwy Des Moines, Iowa 50310</small></p>
                <p><small><ExternalLink
                    href="mailto:ContactUs@wonderyearsacademy.org">ContactUs@wonderyearsacademy.org</ExternalLink> |
                    515-225-6184</small></p>
                <p><small><ExternalLink
                    href="https://educate.iowa.gov/pk-12/operation-support/nutrition-programs#usda-nondiscrimination-statement">USDA Nondiscrimination Statement</ExternalLink>
                    </small>
                    </p>
            </Container>
            <Copyright>
                <p><small>&copy; 2020 | Wonder Years Academy</small></p>
            </Copyright>
        </StyledContainer>
    </FooterWrapper>
);

const SocialIcons = styled.div`
  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  padding: 15px 0;
`;

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export default Footer;
