import React, {useState} from 'react';
import Scrollspy from 'react-scrollspy';
import styled from 'styled-components';
import {graphql, Link, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';

import {Container, Mobile, MobileMenu, Nav, NavItem, NavListWrapper, StyledContainer} from '../global';

import {ReactComponent as MenuIcon} from '../../../static/icons/menu.svg';
import theme from "../../styles/theme";

const NAV_ITEMS = [
    {
        to: '/',
        label: 'Home',
    },
    {
        to: 'our-curriculum',
        label: 'Our Curriculum',
    },
    {
        to: 'parents',
        label: 'Parents',
    },
    {
        to: 'careers',
        label: 'Careers',
    },
    {
        to: 'child-inquiry',
        label: 'Child Inquiry',
    }
];

const Navbar = props => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const renderNavList = ({mobile = false}) => (
        <NavListWrapper mobile={mobile}>
            <Scrollspy
                items={NAV_ITEMS.map(item => item.to)}
                currentClassName="active"
                mobile={mobile}
                offset={-64}
            >
                {NAV_ITEMS.map(navItem => (
                    <NavItem key={navItem.to} mobile={mobile}>
                        <Link to={`/${navItem.to}`}>
                            {navItem.label}
                        </Link>
                    </NavItem>
                ))}
            </Scrollspy>
        </NavListWrapper>
    );

    return (
        <StaticQuery
            query={graphql`
            query {
              logo: file(
                sourceInstanceName: { eq: "brand" }
                name: { eq: "WYA-forwebsite" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 760) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            } 
          `}
            render={data => (
                <Nav {...props}>
                    <StyledContainer>
                        <Art>
                            <Link to={'/'}>
                                <Img fluid={data.logo.childImageSharp.fluid}/>
                            </Link>
                        </Art>

                        <Mobile>
                            <button onClick={toggleMobileMenu} style={{color: theme.color.primary}}>
                                <MenuIcon/>
                            </button>
                        </Mobile>

                        <Mobile hide>
                            {renderNavList({})}
                        </Mobile>
                    </StyledContainer>

                    <Mobile>
                        {mobileMenuOpen && (
                            <MobileMenu>
                                <Container>{renderNavList({mobile: true})}</Container>
                            </MobileMenu>
                        )}
                    </Mobile>
                </Nav>
            )}
        />


    );
};

const Art = styled.figure`
  margin: 0;
  max-width: 300px;
  width: 100%;
  ${props => `
    @media (max-width: ${props.theme.screen.md}) {
        max-width: 200px;
    }
  `}
`;

export default Navbar;
